import React, { useRef } from "react";
import Header from '../../Components/Header/Header';
import HeroSection from './HeroSection';
import ServicesSection from './ServicesSection';
import GreenCleaningSection from './GreenCleaningSection';
import QuoteRequestSection from './QuoteRequestSection';
import Footer from '../../Components/Footer/Footer';
import styles from './BuildingManagement.module.css';
import Meta from '../../Components/Meta/Meta';

function BuildingManagement() {

    const requestQuoteRef = useRef(null);
    
    const scrollToRequestQuote = () => {
      requestQuoteRef.current.scrollIntoView({ behavior: "smooth" });
    };

  return (
    <div className={styles.servicePage}>
      <Meta page="service-management" /> 
      <Header />
      <main>
        <HeroSection onRequestQuoteClick={scrollToRequestQuote} />
        <ServicesSection />
        <section className={styles.tailoredService}>
          <p>Tailored for large buildings, this service expands on our Strata Caretaking offerings, providing specialized, comprehensive care to meet the unique needs of larger properties.</p>
        </section>
        <GreenCleaningSection />
        <QuoteRequestSection ref={requestQuoteRef} />
      </main>
      <img 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/6622f4d3200e435f3ba16b88296c16d7ddf993b47c81f5400ee2de2639ef0a44?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
        alt="" 
        className={styles.decorativeImage} 
      />
      <Footer />
    </div>
  );
}

export default BuildingManagement;