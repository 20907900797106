import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import styles from './PrivacypolicyPage.module.css'
import PrivacyPolicy from "./PrivacyPolicy";


function PrivacyPolicyPage() {
  return (
    <div className={styles.PrivacyPolicyPage}>
      <Header />
      <main>
        <PrivacyPolicy />
      </main>
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/6622f4d3200e435f3ba16b88296c16d7ddf993b47c81f5400ee2de2639ef0a44?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt=""   className={styles.footerImage} />
      <Footer />
    </div>
  );
}

export default PrivacyPolicyPage;