import React, { useState } from 'react';
import Swal from 'sweetalert2';
import FormDataHandler from '../../Components/FormDataHandler/FormDataHandler';
import styles from './ContactForm.module.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    company: '',
    service: 'Strata Caretaking services'
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Format the date as YYYY/MM/DD
    const today = new Date();
    const formattedDate = `${today.getFullYear()}/${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(2, '0')}`;

    // Prepare data for submission
    const formattedData = [
      formattedDate,
      formData.fullName,
      formData.email,
      formData.phone,
      formData.company,
      formData.service
    ];

    // Use FormDataHandler for form submission
    await FormDataHandler({ formType: 'contact', formData: formattedData, onStatusUpdate: handleStatusUpdate });

    // Reset form fields after submission
    setFormData({
      fullName: '',
      email: '',
      phone: '',
      company: '',
      service: 'Strata Caretaking services'
    });
    setIsLoading(false);
  };

  const handleStatusUpdate = (status) => {
    if (status === 'Form submitted successfully!') {
      Swal.fire({
        title: 'Success!',
        text: 'Your form has been submitted.',
        icon: 'success',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        width: '300px',
      });
    } else if (status.includes('Failed')) {
      Swal.fire({
        title: 'Error!',
        text: 'There was an issue submitting the form. Please try again.',
        icon: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        width: '300px',
      });
    }
  };

  return (
    <form className={styles.contactForm} onSubmit={handleSubmit}>
      {isLoading && <div className={styles.loader}>Submitting...</div>}
      
      <label htmlFor="fullName" className={styles.label}>Full Name</label>
      <input
        id="fullName"
        type="text"
        className={styles.input}
        placeholder="Your full name"
        value={formData.fullName}
        onChange={handleChange}
        required
      />

      <label htmlFor="email" className={styles.label}>Email Address</label>
      <input
        id="email"
        type="email"
        className={styles.input}
        placeholder="Email Address"
        value={formData.email}
        onChange={handleChange}
        required
      />

      <label htmlFor="phone" className={styles.label}>Phone</label>
      <input
        id="phone"
        type="tel"
        className={styles.input}
        placeholder="Phone number"
        value={formData.phone}
        onChange={handleChange}
        required
      />

      <label htmlFor="company" className={styles.label}>Company name</label>
      <input
        id="company"
        type="text"
        className={styles.input}
        placeholder="Company / Building name"
        value={formData.company}
        onChange={handleChange}
        required
      />

      <label htmlFor="service" className={styles.label}>What service are you interested in?</label>
      <div className={styles.selectWrapper}>
        <select
          id="service"
          className={styles.select}
          onChange={handleChange}
          value={formData.service}
          required
        >
          <option value="strata-caretaking">Strata Caretaking services</option>
              <option value="strata-cleaning">Strata Cleaning services</option>
              <option value="strata-concierge">Strata Concierge services</option>
              <option value="strata-building-management">Strata Building Management services</option>
              <option value="multiple-services">Multiple Services</option>
        </select>
      </div>

      <button type="submit" className={styles.submitButton}>
        Request a Callback
      </button>
    </form>
  );
};

export default ContactForm;
