import React from 'react';
import styles from './JobPosting.module.css';
import Section from './Section';

const JobPosting = () => {
  const jobDetails = {
    title: "Building Concierge",
    sections: [
      {
        content: (
          <>
            Are you looking for a great teamwork environment and people to support you in your career? Then welcome to Alliance Maintenance! We believe that taking good care of our staff is the secret to our success.
            <br /><br />
            <span className={styles.boldText}>Alliance Maintenance</span> is an expanding building maintenance company servicing residential strata facilities throughout the Metro Vancouver area.
            <ul>
              <li><span className={styles.boldText}>Steady employment.</span> You will work at the same place, at the same time.</li>
              <li><span className={styles.boldText}>On-site training.</span> No direct experience? We can show you how to do it!</li>
              <li><span className={styles.boldText}>Schedule your bills</span> with the peace of mind that you'll be paid every 15th and 30th (or last day) of the month through a direct deposit. Plus 4% Vacation Pay is added to each paycheque.</li>
              <li><span className={styles.boldText}>Health benefits for full-time employees.</span> After your probation, you'll be eligible for medical, vision and dental care.</li>
            </ul>
          </>
        )
      },
      {
        title: "Job Details",
        content: "We are seeking to meet experienced, energetic, and quality candidates for a Building Concierge position. The Concierge is the first person to greet you in the building. They are also there to help support the building manager with the daily tasks and help answer any questions a resident has."
      },
      {
        title: "Available Shifts",
        content: "Monday to Friday from 8:00 AM to 4:00 PM"
      },
      {
        title: "Responsibilities and Duties",
        content: (
          <ul>
            <li>Responsible for being the primary contact and source of information and services for residents and their guests.</li>
            <li>Must have good computer and administration skills.</li>
            <li>Must be presentable.</li>
            <li>Responsible for greeting all residents.</li>
            <li>Help residents get timely resolution on housekeeping and maintenance issues.</li>
            <li>Conduct administrative responsibilities.</li>
            <li>Keep records of resident vehicles, phone numbers, frequent guests, and local maintenance and service providers.</li>
            <li>Ability to work independently in the absence of supervision.</li>
            <li>Ability to work evenings and weekends if required.</li>
            <li>Monitor fire alarm panel to ensure it is operating normally.</li>
            <li>Monitor illegal vehicles parked on premises and tow them if required.</li>
            <li>Perform floor and exterior patrols.</li>
            <li>Attending noise or any other complaints from residents.</li>
            <li>Ability to train new Concierge staff when required.</li>
            <li>Other related duties as required by the client, by the supervisor, or the building manager.</li>
          </ul>
        )
      },
      {
        title: "Knowledge and Skills",
        content: (
          <ul>
            <li>Proficient customer and personal service - actively looking for ways to help people.</li>
            <li>Knowledge of administrative procedures and systems, including picking up phone calls and corresponding emails.</li>
            <li>Must have strong communication skills.</li>
            <li>Must have good coordinating and listening skills.</li>
            <li>Must be able to stay calm under pressure.</li>
            <li>Must dress in a neat and tidy suit and attire.</li>
            <li>Must have knowledge of computers basic programs and systems (Microsoft Office, Internet, Email and Messaging proficiency).</li>
            <li>Must have the ability to multi-task.</li>
            <li>Willingness to work unscheduled shifts and work on holidays.</li>
          </ul>
        )
      }
    ]
  };


  return (
    <main className={styles.container}>
      <article className={styles.contentWrapper}>
        <header className={styles.headerContainer}>
          <h1 className={styles.jobTitle}>{jobDetails.title}</h1>
          <button className={styles.applyButton}>
          <a href="https://ca.indeed.com/cmp/Alliance-Maintenance-Ltd.-1/jobs" 
      target="_blank"
      rel="noopener noreferrer"
      
      >
            <span className={styles.buttonText}>Apply Now</span>
            <img 
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
              alt="" 
              className={styles.buttonIcon} 
              loading="lazy" 
            />
            </a>
          </button>
        </header>
        <div className={styles.mainContent}>
          {jobDetails.sections.map((section, index) => (
            <Section 
              key={index}
              title={section.title}
              content={section.content}
            />
          ))}
        </div>
      </article>
    </main>
  );
};

export default JobPosting;