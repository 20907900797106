import React from 'react';
import styles from './HeroSection.module.css';
import heroImage from '../../Assets/servicePage/bm_hero.png'

function HeroSection({ onRequestQuoteClick }) {

  return (
    <section className={styles.heroSection}>
      <div className={styles.imageContainer}>
        <img src={heroImage} alt="Building Management" className={styles.heroImage} />
      </div>
      <div className={styles.contentContainer}>
        <h1 className={styles.title}>Strata Building Management</h1>
        <p className={styles.description}>
          <strong><em>Is your strata or property management team overwhelmed with administrative tasks? Do strata council members need to take time off work to handle tradespeople and building issues?</em></strong>
          <br /><br />
          There's a simpler solution! For buildings with over 150 units, Alliance Maintenance can manage everything on your behalf. Trust us to handle all of your building's administration duties and issues, including building concierge services, ensuring everything is running smoothly.
        </p>
        <button className={styles.ctaButton} onClick={onRequestQuoteClick}>
          Inquire Now
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="" className={styles.buttonIcon} />
        </button>
      </div>
    </section>
  );
}

export default HeroSection;