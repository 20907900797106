import React, { useState, useEffect, useRef } from 'react';
import styles from './PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
  const [showButton, setShowButton] = useState(false);
  const footerRef = useRef(null);

  // Show/hide "Back to Top" button when scrolling and observing footer visibility
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Intersection Observer for footer visibility
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setShowButton(false); // Hide when footer is visible
        }
      },
      { threshold: 0.1 }
    );

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  // Smooth scroll to top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className={styles.privacyPolicyContainer}>
      <h1 className={styles.title}>Privacy Policy</h1>
      
      <section className={styles.section}>
        <h2 className={styles.subtitle}>Introduction</h2>
        <p className={styles.text}>
          Alliance Maintenance Ltd. (“Alliance Maintenance”) is committed to protecting your privacy.
          This Privacy Policy outlines how we collect, use, disclose, and protect your personal
          information when you interact with our website, services, or marketing materials.
        </p>
      </section>
      
      <section className={styles.section}>
        <h2 className={styles.subtitle}>Information Collection</h2>
        <p className={styles.text}>
          We may collect personal information from you when you:
        </p>
        <ul className={styles.list}>
          <li>Visit our website</li>
          <li>Contact us through our website or by email</li>
          <li>Sign up for our newsletter or other communications</li>
          <li>Download our resources or participate in surveys</li>
          <li>Use our services</li>
          <li>Submit information through website forms or digital marketing channels</li>
        </ul>
        <p className={styles.text}>
          The types of personal information we may collect include but are not limited to:
        </p>
        <ul className={styles.list}>
          <li>Name</li>
          <li>Contact information (email address, phone number)</li>
          <li>Company information (name, industry)</li>
          <li>Website usage information (IP address, browser type)</li>
          <li>Information submitted through website forms (e.g., inquiries, contact requests)</li>
          <li>Information collected through digital marketing channels (e.g., social media interactions, ad clicks)</li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subtitle}>How We Use Your Information</h2>
        <ul className={styles.list}>
          <li>To provide you with the services you have requested</li>
          <li>To communicate with you about our services, promotions, or updates</li>
          <li>To personalize your experience on our website</li>
          <li>To improve our services and website</li>
          <li>To analyze website usage and marketing effectiveness</li>
          <li>To comply with legal requirements</li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subtitle}>Sharing Your Information</h2>
        <p className={styles.text}>
          We do not sell your personal information to third parties. We may share your information with:
        </p>
        <ul className={styles.list}>
          <li>Our service providers who help us deliver our services</li>
          <li>Law enforcement or regulatory authorities as required by law</li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subtitle}>Your Choices</h2>
        <ul className={styles.list}>
          <li>You can opt-out of receiving marketing communications from us at any time.</li>
          <li>You can request access to or correction of your personal information.</li>
          <li>You can request that your personal information be deleted.</li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subtitle}>Data Security</h2>
        <p className={styles.text}>
          We take reasonable measures to protect your personal information from unauthorized access,
          disclosure, or use. However, no method of transmission over the internet or electronic storage
          is completely secure.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subtitle}>Children’s Privacy</h2>
        <p className={styles.text}>
          Our website and services are not directed at children under the age of 13. We do not knowingly
          collect personal information from children under 13.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subtitle}>Changes to This Policy</h2>
        <p className={styles.text}>
          We may update this Privacy Policy from time to time. Any changes can be viewed by visiting this page.
        </p>
      </section>

      <section className={styles.section}>
        <h2 className={styles.subtitle}>Contact Us</h2>
        <p className={styles.text}>
          If you have any questions about this Privacy Policy or our privacy practices, please contact us at:
        </p>
        <address className={styles.address}>
          Alliance Maintenance Ltd.<br />
          info@alliancecleans.com
        </address>
      </section>
    {/* Back to Top button */}
    {showButton && (
        <button
          className={`${styles.backToTopButton} ${showButton ? styles.show : ''}`}
          onClick={scrollToTop}
        >
          Back to Top
        </button>
      )}
    </div>
  );
};

export default PrivacyPolicy;
