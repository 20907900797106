import React from 'react';
import styles from './JobPosting.module.css';
import Section from './Section';

const JobPosting = () => {
  const jobDetails = {
    title: "Building Cleaner",
    sections: [
      {
        content: (
          <>
            Are you looking for a great teamwork environment and people to support you in your career? Then welcome to Alliance Maintenance! We believe that taking good care of our staff is the secret to our success.
            <br /><br />
            <span className={styles.boldText}>Alliance Maintenance</span> is an expanding building maintenance company servicing residential strata facilities throughout the Metro Vancouver area.
            <ul>
              <li><span className={styles.boldText}>Steady employment.</span> You will work at the same place, at the same time.</li>
              <li><span className={styles.boldText}>On-site training.</span> No direct experience? We can show you how to do it!</li>
              <li><span className={styles.boldText}>Schedule your bills</span> with the peace of mind that you'll be paid every 15th and 30th (or last day) of the month through a direct deposit. Plus 4% Vacation Pay is added to each paycheque.</li>
              <li><span className={styles.boldText}>Health benefits for full-time employees.</span> After your probation, you'll be eligible for medical, vision and dental care.</li>
            </ul>
          </>
        )
      },
      {
        title: "Job Details",
        content: "We would love to meet energetic and willing-to-learn candidates for a building cleaner position. Here, you will be responsible for cleaning and maintaining the common areas of a residential building."
      },
      {
        title: "Available Shifts",
        content: "Monday to Friday from 8:00 AM to 4:00 PM"
      },
       {
        title: "Main Duties",
        content: (
          <ul>
            <li>Sweep and mop, wash, wax, and polish floors and stairwells</li>
            <li>Vacuum carpets and upholstered furniture</li>
            <li>Dust and polish furniture</li>
            <li>Clean and polish elevators</li>
            <li>Wash windows, walls, and ceilings</li>
          </ul>
        )
      },
      {
        title: "Requirements",
        content: (
          <ul>
            <li>English Language — Knowledge of the structure and content of the English language including the meaning and spelling of words, rules of composition, and grammar.</li>
            <li>Knowledge of standard cleaning procedures</li>
            <li>Ability to read and understand labels and instructions of cleaning chemicals</li>
            <li>Ability to answer Company or customer-issued telecommunication devices</li>
            <li>Ability to work with little supervision</li>
            <li>Ability to work alone</li>
            <li>Ability to climb a 5ft step ladder</li>
            <li>Ability to lift and carry up to 20 pounds</li>
            <li>Ability to use cleaning chemical products</li>
            <li>Agree to clean dog poop, human poop, and vomit</li>
            <li>Agree to clean a garbage room</li>
            <li>Ability to use a smartphone</li>
            <li>Agree to use a personal smartphone for clocking in and out and company communication</li>
          </ul>
        )
      }
    ]
  };

  return (
    <main className={styles.container}>
      <article className={styles.contentWrapper}>
        <header className={styles.headerContainer}>
          <h1 className={styles.jobTitle}>{jobDetails.title}</h1>
          <button className={styles.applyButton}>
          <a href="https://ca.indeed.com/cmp/Alliance-Maintenance-Ltd.-1/jobs" 
      target="_blank"
      rel="noopener noreferrer"
      
      >
            <span className={styles.buttonText}>Apply Now</span>
            <img 
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
              alt="" 
              className={styles.buttonIcon} 
              loading="lazy" 
            />
            </a>
          </button>
        </header>
        <div className={styles.mainContent}>
          {jobDetails.sections.map((section, index) => (
            <Section 
              key={index}
              title={section.title}
              content={section.content}
            />
          ))}
        </div>
      </article>
    </main>
  );
};

export default JobPosting;