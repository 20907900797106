import React from 'react';
import styles from './GreenCleaningSection.module.css';

function GreenCleaningSection() {
  return (
    <section className={styles.greenCleaningSection}>
      <div className={styles.imageContainer}>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/a551584b36a5cfdfe56723673e53286048c882bbb02d90c9e8b0367986ce079a?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="Green Cleaning Practices" className={styles.greenCleaningImage} />
      </div>
      <div className={styles.contentContainer}>
        <h2 className={styles.title}>Green Cleaning</h2>
        <p className={styles.description}>
          The advantages of eco-friendly chemicals extend well beyond cost concerns. The outdated myth that 'green chemicals' are too expensive and ineffective no longer holds true. Thanks to technological advances, many effective chemicals are now biodegradable. With an experienced team, the right eco-friendly products, and efficient cleaning methods, it's possible to reduce your building's carbon footprint!
        </p>
      </div>
    </section>
  );
}

export default GreenCleaningSection;