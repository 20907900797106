import React, { useEffect, useRef } from "react";
import styles from "./MissionStatement.module.css";

function MissionStatement() {
  const videoRef = useRef(null);

  useEffect(() => {
    // Observer callback
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoRef.current.src = "https://www.youtube.com/embed/m9_zfMUz8WM?autoplay=1";
        } else {
          videoRef.current.src = "https://www.youtube.com/embed/m9_zfMUz8WM";
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, 
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <section className={styles.missionStatement}>
      <h2 className={styles.missionTitle}>
        Our Mission is to End the Stigma Associated With Being a Cleaner
      </h2>
      <div className={styles.missionVideoContainer}>
        <iframe
          ref={videoRef}
          className={styles.missionVideo}
          src="https://www.youtube.com/embed/m9_zfMUz8WM"
          title="Alliance Maintenance Mission Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
}

export default MissionStatement;
