import React from 'react';
import styles from './OurStory.module.css';

function OurStory() {
  return (
    <section className={styles.ourStory}>
      <h2 className={styles.title}>Our Story</h2>
      <p className={styles.description}>
        Childhood friends Nish Singh and Shanil Sharma were inspired to start Alliance Maintenance after hearing from a family friend who worked as a cleaner. She shared how she loved what she did, but hated how she was being treated—undervalued and overworked.
        <br /><br />
        Talking with her pushed them to start a cleaning company that treats its workers with kindness and its clients with care. Today, Alliance Maintenance Ltd. has over 300 employees that expertly care for more than 150 high-end strata sites across Metro Vancouver.
      </p>
      <h3 className={styles.subtitle}>Building a Culture of Care</h3>
      <div className={styles.videoWrapper}>
        <div className={styles.gradientOverlay}></div>
        <iframe
          className={styles.youtubeVideo}
          src="https://www.youtube.com/embed/qK1NMfcHkqo?autoplay=1&mute=1&loop=1&playlist=qK1NMfcHkqo"
          title="Alliance Maintenance Culture Video"
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
}

export default OurStory;
