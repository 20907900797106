import React from 'react';
import styles from './ServicesSection.module.css';

function ServicesSection() {
  const services = [
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c68a0374b95eeefc8e08e117107b514816051bf1dbde2ab81ba1e015aad63153?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", text: "Site maintenance" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a0f3b29f9516e863a77f89bbf8c66b4ec8472669adff325aabd2f9b21b95cfd6?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", text: "Access and security" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/00209c1f93eb40f6f209d42aa081b16d6c2df225ba498e18cf2054a3a2e0b98e?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", text: "Trades coordination" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3f23867e0d7ef9ef7efd55c6f1c88a3280c87ae99365fbb3f9fa3de0d3403af1?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", text: "Administrative duties" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1e58e9cab9972c73d612832df7300d91b02932a81892dc29652509d9075fc2a3?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", text: "Parking management" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/de3e4da83d3a71bf25a25e45a187940649acca1da251bd024f5c10ec67690b00?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", text: "Property manager relations" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c95a0b5c762ed844daa187c726cb7266f4b52920c0e9c249fc2dd932fa1da718?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", text: "Tenant relations and management" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8e72805f15b8e828725d34d304d6941425423ee9c022a101e8eb68efa82c834d?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", text: "Booking management" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e8164225f77d73b3ae798dbdd38657f505fb94d4e4c74167dae4482402afb0d3?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", text: "Move management" }
  ];

  return (
    <section className={styles.servicesSection}>
      <h2 className={styles.sectionTitle}>Building Management Services We Offer</h2>
      <div className={styles.servicesWrapper}>
      <div className={styles.servicesContainer}>
        <h3 className={styles.servicesSubtitle}>Our Vancouver building management services, delivered by our experienced team:</h3>
        <ul className={styles.servicesList}>
          {services.map((service, index) => (
            <li key={index} className={styles.serviceItem}>
              <img src={service.icon} alt="" className={styles.serviceIcon} />
              <span>{service.text}</span>
            </li>
          ))}
        </ul>
        </div>
      </div>
    </section>
  );
}

export default ServicesSection;