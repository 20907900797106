import React from "react";
import JobPosting from "./JobPosting";
import HiringProcess from "../Contact/HiringProcess";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import styles from './BuildingManagerJobPosting.module.css';
import Meta from "../../Components/Meta/Meta";
import s1 from '../../Assets/separators/separator1.png'

function BuildingManagerJobPosting() {
  return (
    <div className={styles.servicePage}>
      <Meta page="default" /> 
      <Header />
    <main>
        <JobPosting/>
        <img src = {s1} className={styles.separator}/>
        <HiringProcess/>
    </main>
      <img 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/6622f4d3200e435f3ba16b88296c16d7ddf993b47c81f5400ee2de2639ef0a44?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
        alt="" 
        
      />
      <Footer />
      
    </div>
  );
}

export default BuildingManagerJobPosting;