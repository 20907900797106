import React from "react";
import ServiceCard from "./ServiceCard";
import styles from "./StrataCareComponent.module.css";

const services = [
  {
    title: "Janitorial Services",
    description: "We promise detailed strata cleaning and floor care for your building, ensuring a clean and hygienic environment for residents and their guests.",
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/28fabf3f9b401a9b2b619f48c2dcbf84366630d020dcc1bac873116ecaff3bc2?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f",
    link: "/service/strata-cleaning/",
  },
  {
    title: "Strata Caretaking",
    description: "Let us take care of the daily upkeep and maintenance of your building. This is your strata's all-in-one solution for building management and cleaning.",
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/172df84a00974fe6e097daa893794ec98ac0bd53f2b9a206bd37f5ddc7bc5560?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f",
    link: "/service/strata-Caretaking",
  },
  {
    title: "Strata Concierge",
    description: "Build a safe, tight-knit community with the support of our professional concierge staff.",
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/04f83e08322d0c95f77082a3ca4ffbe33c5be3a0caf32455ed2aee988e627bf8?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f",
    link: "/service/strata-concierge",
  },
  {
    title: "Building Management",
    description: "Comprehensive building management for large strata buildings.",
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/509809cf2475036a3feeaf5c75d95a6ceb02b7f58cf999c187b68643430a7da4?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f",
    link: "/service/strata-building-management/",
  },
];

function StrataCareComponent() {
  return (
    <main className={styles.mainContainer}>
      <section className={styles.contentWrapper}>
        <div className={styles.headerSection}>
          <h1 className={styles.mainTitle}>
            Your One-Stop for Complete Strata Care in Vancouver
          </h1>
          <p className={styles.mainDescription}>
            Feel the difference with our comprehensive strata maintenance services, delivered by a caring and dedicated team with years of expertise. Say goodbye to unreliable, fragmented service providers. You've worked hard for your strata building, and we're committed to maintaining it with the utmost care.
          </p>
        </div>
      </section>
      <section id="service-cards" className={styles.servicesGrid}>
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            title={service.title}
            description={service.description}
            imageUrl={service.imageUrl}
            link={service.link}
          />
        ))}
      </section>
    </main>
  );
}

export default StrataCareComponent;
