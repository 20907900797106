import React, { useState, forwardRef } from "react";
import Swal from 'sweetalert2';
import FormDataHandler from '../../Components/FormDataHandler/FormDataHandler';
import styles from '../../Components/QuoteForm/QuoteFormCommon.module.css';

const QuoteRequest = forwardRef((props, ref) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    service: "strata-cleaning",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Format the date as YYYY/MM/DD
    const today = new Date();
    const formattedDate = `${today.getFullYear()}/${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(2, '0')}`;

    // Prepare the data for the first four columns only
    const formattedData = [
      formattedDate,
      formData.fullName,
      formData.email,
      formData.phone
    ];

    // Use FormDataHandler for submission
    await FormDataHandler({ formType: "requestQuote", formData: formattedData, onStatusUpdate: handleStatusUpdate });

    // Reset form after submission
    setFormData({
      fullName: "",
      email: "",
      phone: "",
      service: "strata-cleaning",
    });
    setIsLoading(false);
  };

  const handleStatusUpdate = (status) => {
    if (status === "Form submitted successfully!") {
      Swal.fire({
        title: 'Success!',
        text: 'Your form has been submitted.',
        icon: 'success',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        width: '300px',
      });
    } else if (status.includes("Failed")) {
      Swal.fire({
        title: 'Error!',
        text: 'There was an issue submitting the form. Please try again.',
        icon: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        width: '300px',
      });
    }
  };

  return (
    <section className={styles.quoteRequestSection} ref={ref}>
      <div className={styles.contentContainer}>
        <h2 className={styles.title}>Request a Cleaning Service Quote</h2>
        <p className={styles.description}>
        Whether you're looking to switch from your current strata cleaning company or need quality, reliable solutions for a new building in Vancouver and the greater Lower Mainland, we're here to help. Get in touch now for a cleaning quote for your strata.
        </p>
      </div>

      {isLoading ? (
        <div className={styles.loader}>
          <p>Submitting...</p>
          <div className={styles.spinner}></div>
        </div>
      ) : (
        <form className={styles.quoteForm} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="fullName" className={styles.label}>Full Name</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              placeholder="Your full name"
              className={styles.input}
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email" className={styles.label}>Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email Address"
              className={styles.input}
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="phone" className={styles.label}>Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Phone number"
              className={styles.input}
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="service" className={styles.label}>What service are you interested in?</label>
            <select
              id="service"
              name="service"
              className={styles.select}
              value={formData.service}
              onChange={handleChange}
              required
            >
              <option value="">Select a service</option>
              <option value="strata-caretaking">Strata Caretaking services</option>
              <option value="strata-cleaning">Strata Cleaning services</option>
              <option value="strata-concierge">Strata Concierge services</option>
              <option value="strata-building-management">Strata Building Management services</option>
              <option value="multiple-services">Multiple Services</option>
            </select>
          </div>
          <button type="submit" className={styles.submitButton}>
            Request a Callback
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="" className={styles.buttonIcon} />
          </button>
        </form>
      )}
    </section>
  );
});

export default QuoteRequest;
