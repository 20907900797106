import React from 'react';
import styles from './SocialLinks.module.css';

const SocialLinks = () => {
  const socialIcons = [
    { 
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/3cfeeded77a153db3fb22e6afd0defcaaa35bc0cc64265415fb5cc854ff92a5b?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", 
      alt: "Facebook", 
      link: "https://www.facebook.com/alliancecleans/" 
    },
    { 
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/8f1c4a9e07132aa572738ded71d82144ee6d343e324033f8c0fa0ed0abebd7b1?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", 
      alt: "Instagram", 
      link: "https://www.instagram.com/alliancecleans/" 
    },
    { 
      src: "https://cdn.builder.io/api/v1/image/assets/TEMP/1d308ea06ed4fc7464be548f3433d94cd969b051ca3c02d3c1391296a442292d?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", 
      alt: "LinkedIn", 
      link: "https://www.linkedin.com/company/alliance-maintenance-ltd-/?originalSubdomain=ca" 
    }
  ];

  return (
    <div className={styles.socialLinksContainer}>
      <p className={styles.followText}>Follow us on</p>
      <div className={styles.iconWrapper}>
        {socialIcons.map((icon, index) => (
          <a key={index} href={icon.link} target="_blank" rel="noopener noreferrer">
            <img src={icon.src} alt={icon.alt} className={styles.socialIcon} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialLinks;
