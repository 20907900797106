import React from 'react';
import styles from './HeroSection.module.css';
import heroImage from '../../Assets/homepage/HeroImage.png';
import homepageseparator from '../../Assets/separators/homepagehero.png';

function HeroSection() {
  return (
    <section className={styles.heroSection}>
      <div className={styles.textContainer}>
        <h1 className={styles.title}>
          Supporting<br /> Communities With <br />Our Full-Service Strata Maintenance Solutions
        </h1>
        <p className={styles.description}>
          Let us take away your stress by expertly handling all of your property maintenance needs, from ongoing
          maintenance to cleaning and concierge services.
        </p>
        <div className={styles.contactButtonContainer}>
          <a href="/contact" className={styles.contactText}>
            Contact Us Now
          </a>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f"
            alt=""
            className={styles.buttonIcon}
          />
        </div>
      </div>

      <div className={styles.imageContainer}>
        <img src={heroImage} alt="Maintenance worker" className={styles.heroImage} />
        <div className={styles.gradientOverlay}></div>
      </div>

      
      {/* Separator positioned at the bottom */}
      <div className={styles.separatorWrapper}>
        <img src={homepageseparator} alt="Separator" className={styles.separator} />
      </div>
    </section>
  );
}

export default HeroSection;
