import React from 'react';
import Header from '../../Components/Header/Header';
import HeroSection from './HeroSection';
import OurStory from './OurStory';
import AllianceStandard from './AllianceStandard';
import OurValues from './OurValues';
import TeamMember from './TeamMembers';
import JoinTeam from './JoinTeam';
import Footer from '../../Components/Footer/Footer';
import styles from './AboutUs.module.css';
import Meta from '../../Components/Meta/Meta';
import s3 from'../../Assets/separators/Vector5.png';
import s4 from'../../Assets/separators/downSeparator.png';

function AboutUs() {
  return (
    <div className={styles.aboutUsContainer}>
       <Meta page="about" /> 
      <Header />
      <main>
        <HeroSection />
        <img src={s3} alt="Separator" className={styles.separator} />
        <OurStory />
        <img src={s4} alt="Separator" className={styles.separator} />
        <AllianceStandard />
        <OurValues />
        <TeamMember />
        <JoinTeam />
      </main>
      <img 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/6622f4d3200e435f3ba16b88296c16d7ddf993b47c81f5400ee2de2639ef0a44?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
        alt="" 
        className={styles.decorativeImage} 
      />
      <Footer />
    </div>
  );
}

export default AboutUs;