import React, { useState } from 'react';
import Swal from 'sweetalert2';
import FormDataHandler from '../../Components/FormDataHandler/FormDataHandler';
import styles from './ContactForm.module.css';

function ContactForm() {
  const [formData, setFormData] = useState({
    fullName: "",
    emailAddress: "",
    phone: "",
    service: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Format the date as YYYY/MM/DD
    const today = new Date();
    const formattedDate = `${today.getFullYear()}/${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(2, '0')}`;

    // Prepare the data for the form submission
    const formattedData = [
      formattedDate,
      formData.fullName,
      formData.emailAddress,
      formData.phone,
      formData.service
    ];

    // Invoke the FormDataHandler with formatted data
    await FormDataHandler({ formType: "contact", formData: formattedData, onStatusUpdate: handleStatusUpdate });

    // Reset the form after submission
    setFormData({
      fullName: "",
      emailAddress: "",
      phone: "",
      service: "",
    });
    setIsLoading(false);
  };

  const handleStatusUpdate = (status) => {
    if (status === "Form submitted successfully!") {
      // Show success notification
      Swal.fire({
        title: 'Success!',
        text: 'Your form has been submitted.',
        icon: 'success',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        width: '300px',
      });
    } else if (status.includes("Failed")) {
      // Show error notification
      Swal.fire({
        title: 'Error!',
        text: 'There was an issue submitting the form. Please try again.',
        icon: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        width: '300px',
      });
    }
  };

  return (
    <section className={styles.contactSection}>
      <div className={styles.contactContent}>
        <h2 className={styles.contactTitle}>Let Us Support You in Caring for Your Building</h2>
        <p className={styles.contactDescription}>
          Looking for expert advice or a quote? We'd love to hear from you. Fill out a form to connect with us and explore how we can assist with all your property management needs.
        </p>
      </div>

      {isLoading ? (
        <div className={styles.loader}>
          <p>Submitting...</p>
          <div className={styles.spinner}></div>
        </div>
      ) : (
        <form className={styles.contactForm} onSubmit={handleSubmit}>
          <label htmlFor="fullName" className={styles.formLabel}>Full Name</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            placeholder="Your full name"
            className={styles.formInput}
            value={formData.fullName}
            onChange={handleChange}
            required
          />

          <label htmlFor="emailAddress" className={styles.formLabel}>Email Address</label>
          <input
            type="email"
            id="emailAddress"
            name="emailAddress"
            placeholder="Email Address"
            className={styles.formInput}
            value={formData.emailAddress}
            onChange={handleChange}
            required
          />

          <label htmlFor="phone" className={styles.formLabel}>Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Phone number"
            className={styles.formInput}
            value={formData.phone}
            onChange={handleChange}
            required
          />

          <label htmlFor="service" className={styles.formLabel}>What service are you interested in?</label>
          <div className={styles.selectWrapper}>
            <select
              id="service"
              name="service"
              className={styles.formSelect}
              value={formData.service}
              onChange={handleChange}
              required
            >
              <option value="">Select a service</option>
              <option value="strata-caretaking">Strata Caretaking services</option>
              <option value="strata-cleaning">Strata Cleaning services</option>
              <option value="strata-concierge">Strata Concierge services</option>
              <option value="strata-building-management">Strata Building Management services</option>
              <option value="multiple-services">Multiple Services</option>
            </select>
          </div>

          <button type="submit" className={styles.submitButton}>
            Submit Form
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f"
              alt=""
              className={styles.buttonIcon}
            />
          </button>
        </form>
      )}
    </section>
  );
}

export default ContactForm;
