import React from 'react';
import { useState } from 'react';
import styles from './AllianceStandard.module.css';

function AllianceStandard() {

  const [expandedIndex, setExpandedIndex] = useState(null);

  const standardIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/93ab3df7542f3d7e21eef0490205a04372fe9ddd8422d43dc4390678a4a7f3a3?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f";

  const standards = [
    { title: "Working with Property Managers", icon: standardIcon, description: "Property managers are busy, and we get it. That’s why our account supervisors maintain consistent contact, including regular, proactive updates (often weekly). This dedication to communication has made Alliance Maintenance Ltd. a preferred partner for major strata management companies in Western Canada." },
    { title: "Working with Strata Councils", icon: standardIcon, description: "We encourage you to meet our management team early on. This allows us to ensure a smooth start to our partnership. We promise each building has a dedicated account supervisor to address all your concerns. YOU are our top priority, and our hardworking team is committed to listening, planning, and executing efficiently." },
    { title: "Working with Real Estate Developers", icon: standardIcon, description:"Your residential strata building is at its cleanest and most efficient when all parties (including building managers and concierge staff) are working together and on the same page. Whether it's a coffee spill, dog on the loose, or a curbside mess, our team works closely with building managers and concierge staff to stay on top of it. Real estate developers build communities with a long term vision. And AML upholds that vision. Immediately following the “occupancy permit” AML understands how to help you build a strong community around your vision. Our reputation is build around getting it done for you the way you want." }
  ];

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <section className={styles.allianceStandard}>
      <div className={styles.allianceStandardContent}>
        <h2 className={styles.title}>The Alliance Standard</h2>
        <p className={styles.description}>
          At Alliance Maintenance Ltd., we set ourselves apart by going above and beyond for our clients all over British Columbia's Lower Mainland. Our supervisory team maintains constant communication to ensure your needs are always addressed. We strive to elevate the standards of service, delivering exceptional results tailored to your specific requirements, while also working closely with key members of your team.
        </p>
      </div>
      <div className={styles.standardsAndImage}>
        <div className={styles.standardsContainer}>
          {standards.map((standard, index) => (
            <div key={index} className={styles.standardItem}>
              <div className={styles.standardHeader} onClick={() => handleToggle(index)}>
                <h3 className={styles.standardTitle}>{standard.title}</h3>
                <img src={standard.icon} alt="" className={styles.standardIcon} />
              </div>
              {expandedIndex === index && (
                <p className={styles.standardDescription}>{standard.description}</p>
              )}
            </div>
          ))}
        </div>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e9c54ccef85d4cf98a6225656c9af437f290a5037fb9a29a0dc5dd1b6a63329b?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f"
          alt="Alliance Maintenance team in action"
          className={styles.teamImage}
        />
      </div>
    </section>
  );
}
export default AllianceStandard;
