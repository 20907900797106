import React from 'react';
import styles from './HeroSection.module.css';

function HeroSection() {
  return (
    <>
      <div style={{ height: '50px' }}></div> {/* Spacer div to add space above */}
    <section className={styles.heroSection}>
      <div className={styles.imageWrapper}>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/d3d6bee5a85342c2b9fbff2e9630f4b3d33b09d22fa89cd31b15d4f5324974cb?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="Alliance Maintenance team at work" className={styles.heroImage} />
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>About Alliance Maintenance Ltd.</h1>
        <p className={styles.description}>
          <span className={styles.strongText}><strong>As a premier strata maintenance and janitorial company serving Vancouver and the Lower Mainland, we pride ourselves on our unique approach.</strong></span>
          <br /><br />
          We believe that taking exceptional care of our staff allows them to take exceptional care of our clients. Experience the difference with our dedicated team.
        </p>
        <a href="/contact" className={styles.contactButton}>
          Contact Us
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="" className={styles.arrowIcon} />
        </a>
      </div>
    </section>
    </>
  );
}

export default HeroSection;
