// SpecializedCleaning.jsx
import React from 'react';
import styles from './Specializedcarecommonstyle.module.css';
import carpetCleaningImage from '../../Assets/servicePage/cleaning/CarpetCleaning.png'

function SpecializedCleaning() {
  return (
    <div className={styles.sectionGrid}>
      {/* Specialized Carpet Care */}
      <div className={styles.contentWrapper}>
        <h2 className={styles.sectionTitle}>Specialized Carpet Care</h2>
        <p className={styles.description}>
        You deserve to have carpets that look and feel clean at all times. We provide various cost-effective solutions tailored to benefit your strata. Our specialized carpet cleaning and floor maintenance programs run year-round. With our certified journeymen’s extensive experience, you can trust in the long-lasting care of your carpets.
        </p>
      </div>
      <img src={carpetCleaningImage} alt="Specialized Carpet Care"   className={styles.sectionImage}    />

      {/* Green Cleaning */}
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/a551584b36a5cfdfe56723673e53286048c882bbb02d90c9e8b0367986ce079a?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f"
        alt="Green Cleaning"
        className={styles.sectionImage}
      />
      <div className={styles.contentWrapper}>
        <h2 className={styles.sectionTitle}>Green Cleaning</h2>
        <p className={styles.description}>
        The advantages of eco-friendly chemicals extend well beyond cost concerns. The outdated myth that 'green chemicals' are too expensive and ineffective no longer holds true. Thanks to technological advances, many effective chemicals are now biodegradable. With an experienced team, the right eco-friendly products, and efficient cleaning methods, it’s possible to reduce your building's carbon footprint!
        </p>
      </div>
    </div>
  );
}

export default SpecializedCleaning;
