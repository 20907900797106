import React from 'react';
import styles from './JobPosting.module.css';
import Section from './Section';

const JobPosting = () => {
  const jobDetails = {
    title: "Building Caretaker",
    sections: [
      {
        content: (
          <>
            Are you looking for a great teamwork environment and people to support you in your career? Then welcome to Alliance Maintenance! We believe that taking good care of our staff is the secret to our success.
            <br /><br />
            <span className={styles.boldText}>Alliance Maintenance</span> is an expanding building maintenance company servicing residential strata facilities throughout the Metro Vancouver area.
            <ul>
              <li><span className={styles.boldText}>Steady employment.</span> You will work at the same place, at the same time.</li>
              <li><span className={styles.boldText}>On-site training.</span> No direct experience? We can show you how to do it!</li>
              <li><span className={styles.boldText}>Schedule your bills</span> with the peace of mind that you'll be paid every 15th and 30th (or last day) of the month through a direct deposit. Plus 4% Vacation Pay is added to each paycheque.</li>
              <li><span className={styles.boldText}>Health benefits for full-time employees.</span> After your probation, you'll be eligible for medical, vision and dental care.</li>
            </ul>
          </>
        )
      },
      {
        title: "Job Details",
        content: "We are seeking to meet experienced, energetic, and quality candidates for a Building Caretaker position. You’ll be responsible for supporting building operations and assisting with some administrative tasks. Also, you will maintain the cleanliness of the building on a daily basis."
      },
      {
        title: "Available Shifts",
        content: "Monday to Friday from 8:00 AM to 4:00 PM"
      },
      {
        title: "Responsibilities and Duties",
        content: (
          <ul>
            <li>Clean the building.</li>
            <li>Assist in building move-ins / move-outs.</li>
            <li>Proceed with building inspection and security.</li>
            <li>Program enter-phone codes and fobs.</li>
            <li>Assist contractors.</li>
            <li>Report building issues in writing.</li>
            <li>Assist First Responders and execute emergency protocols if needed.</li>
            <li>Other duties as required.</li>
          </ul>
        )
      },
      {
        title: "What We Need from You",
        content: (
          <ul>
            <li>Strong communication skills.</li>
            <li>Well-organized and detail-oriented.</li>
            <li>Computer skills (Microsoft Office, Internet, Email, and Messaging proficiency).</li>
            <li>Some understanding of cleaning equipment and solutions.</li>
            <li>Reliable and punctual with a strong work ethic.</li>
            <li>Positive and friendly attitude.</li>
            <li>Able to climb a 5ft step ladder.</li>
            <li>Able to lift and carry up to 20 pounds.</li>
            <li>Able to use cleaning chemical products.</li>
            <li>Open to clean light biohazard waste.</li>
          </ul>
        )
      }
    ]
  };


  return (
    <main className={styles.container}>
      <article className={styles.contentWrapper}>
        <header className={styles.headerContainer}>
          <h1 className={styles.jobTitle}>{jobDetails.title}</h1>
          <a
            href="https://ca.indeed.com/cmp/Alliance-Maintenance-Ltd.-1/jobs"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.applyButton}
          >
            <span className={styles.buttonText}>Apply Now</span>
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f"
              alt=""
              className={styles.buttonIcon}
              loading="lazy"
            />
          </a>
        </header>
        <div className={styles.mainContent}>
          {jobDetails.sections.map((section, index) => (
            <Section
              key={index}
              title={section.title}
              content={section.content}
            />
          ))}
        </div>
      </article>
    </main>
  );
};

export default JobPosting;