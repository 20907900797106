import React from 'react';
import styles from './ServicesOffered.module.css';

const services = [
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/133fa476782b740a27cac61d82677582df620671a0caa5008d3eafaffd5ade0a?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", name: "Exterior grounds" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a0f3b29f9516e863a77f89bbf8c66b4ec8472669adff325aabd2f9b21b95cfd6?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", name: "Lobby or foyer" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ad5b4cfb457d415d9bf396f5083121ca9358bc5616784d740be3fb240ca99920?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", name: "Elevators" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6ab2ec0f402cc5d6d610c254b3fbe2c1de581750ef96d58caf9cbb00c97eddd1?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", name: "Hallways" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d4f42741290ba70c42284ad8305451f50c19671632b2cf3118833f987408cffc?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", name: "Amenity room" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/56d1c092ab3893939f86a69139e0730faa7740f25079f65c807088df691f5008?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", name: "Fitness room" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/7c71503b6d6fb4185c2a1f00a85ee1f5ab961be8946a6965af30a6b4d432f542?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", name: "Parkade and parkade vestibules" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5bc498db7de45d472c6944b03bd4f062c7b7c1068075b9ae421408dad5fe0081?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", name: "Garbage room" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/90d48beece4ffbe5bad8d2e164f863a6be08e0304cf71114195bd688ee1b9a0a?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", name: "Stairwells" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e18903580fdd5af985d25177c952d304f513844e750872d952ef24c6136e39ad?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", name: "Mechanical and electrical room" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5de0be7e3117dddc4e8f6c3f5a2ff6e0201c57b1f38385e84965a4e82c0deb69?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", name: "Swimming pool area" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b44968f25d66ad17e47e2d5d14ad7b4b35bfef470085ca05329694fca8b88920?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", name: "Sauna" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3890adb33eecf9b17bfe55105cd64f8f83bb71b75550cbe963545cef457fc732?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", name: "Dog wash station" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a5adce35b83e1b2f7c202cef861a8deede747be315e0b085477e4b705ad58997?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", name: "Guest suite" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b73bf2b66e10a0db5910005ed5ffcc6b70fe84c7d66267c1998f66088f7a26b3?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", name: "Garden courtyard or amenity room patio" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6951709494a70647ddf22ee9e66acce8967a023edbfa53ef2d68a718b57e89f1?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", name: "Change rooms, showers, and restrooms" }
];

function ServicesOffered() {
  return (
    <section className={styles.servicesSection}>
      <h2 className={styles.sectionTitle}>Strata Cleaning Services We Offer</h2>
      <div class={styles.servicesWrapper}>
      <div className={styles.servicesContainer}>
     
      <p className={styles.servicesIntro}>
          We take pride in offering thorough building cleaning services, <br/>
          covering these essential areas to maintain a spotless and <br/>
          welcoming environment throughout your property:
        </p>
        <ul className={styles.servicesList}>
          {services.map((service, index) => (
            <li key={index} className={styles.serviceItem}>
              <img src={service.icon} alt={`${service.name} icon`} className={styles.serviceIcon} />
              <span>{service.name}</span>
            </li>
          ))}
        </ul>
      </div>
      </div>
    </section>
  );
}

export default ServicesOffered;
