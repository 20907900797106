import React, { useState, useEffect } from 'react';
import styles from './JoinTeam.module.css';

function JoinTeam() {
  const testimonials = [
    {
      quote: "I have hired the Alliance crew for some of my clients and they have been great to work with and always responsive. They vet and monitor their staff well, make sure the job is well done and even checkup to see if all is going well.",
      author: "Irene K",
      position: "Property Owner"
    },
    {
      quote: "As a member of the strata council for two condo buildings in Richmond, I've been using AML for building maintenance, and they have been nothing short of amazing. Their team is professional, responsive, and always goes the extra mile to ensure everything is in top condition. Highly recommended for anyone looking for reliable and efficient building maintenance services.",
      author: "Eric P",
      position: "Strata Council"
    },
    {
      quote: "Alliance Maintenance exceeded our expectations with their professionalism and attention to detail. The staff were friendly, accommodating, and treated our property with care. I highly recommend Alliance Maintenance for all cleaning needs; They clearly invest in great people, but also top-notch equipment. I noticed the high-powered vacuums that they used left our office floors immaculate.",
      author: "Shan P",
      position: "Property Owner"
    },
    {
      quote: "Alliance Maintenance is professional, responsive and has amazing service both on the staff and management side. They are open to feedback from the clients to ensure that a great fit will be placed on site. Their supervisors are very hands on and will check in on the site staff to ensure consistent service. I feel confident recommending Alliance to all my clients.",
      author: "Carmen Z",
      position: "Senior Strata Agent"
    },
    {
      quote: "Alliance is the concierge and cleaning company in our building. We just moved in and were very impressed on how clean the building was and how helpful the concierge was on our move. The company made our move very seamless and we could not be any more grateful that they saved us from any headaches.",
      author: "Marilyn A",
      position: "Property Owner"
    }
  ];

  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  // Function to update the index to the next testimonial
  const nextTestimonial = () => {
    setCurrentTestimonialIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  // Automatically switch testimonials every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(nextTestimonial, 5000); // Change testimonial every 5000 ms (5 seconds)

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Manual navigation through dots
  const handleNavDotClick = (index) => {
    setCurrentTestimonialIndex(index);
  };

  const currentTestimonial = testimonials[currentTestimonialIndex];

  return (
    <section className={styles.testimonialSection}>
      <div className={styles.testimonialContent}>
        <div className={styles.ellipseBackground}></div>
        <div className={styles.testimonialTitle}>
          <h2>Join the Team</h2>
        </div>
        <p>Our team members love being part of Alliance. Find out why they value our people-first company culture.
        </p>
      </div>
      <div className={styles.testimonialCard}>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/fcd94a57b370380a507150a98e3e4f4bf3bb39c42725d7e20ded87533025b3c6?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f"
          alt=""
          className={styles.quoteOpenIcon}
        />
        <blockquote>{currentTestimonial.quote}</blockquote>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/1e0c86a42d77c799eb4ca3b022413af4c6562a8007e8fe4837328f21fde4fbc1?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f"
          alt=""
          className={styles.quoteCloseIcon}
        />
        <footer>
          <cite>{currentTestimonial.author}</cite>
          <p>{currentTestimonial.position}</p>
        </footer>
        <div className={styles.testimonialNav}>
          {testimonials.map((_, index) => (
            <span
              key={index}
              className={`${styles.navDot} ${index === currentTestimonialIndex ? styles.active : ''}`}
              onClick={() => handleNavDotClick(index)}
            ></span>
          ))}
        </div>
      </div>
    </section>
  );
}

export default JoinTeam;
