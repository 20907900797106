import React from 'react';
import styles from './OurValues.module.css';

function OurValues() {
  const values = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/fb2c3fcc076469853683cc7ff42fc468ae2af43d94751938529996e4003fd479?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f",
      title: "Caring & Supportive",
      description: "At Alliance, we work hard to create a workplace where everyone feels valued and supported. We believe that when our team feels cared for, they bring that same level of care to our clients. We're building a community where compassion and support are at the core of everything we do."
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ae7d95ea4d7e7a3060eb4c591e9d0a7b28e78247fc7f0934f9f96b1fb558366a?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f",
      title: "Do the Right Thing",
      description: "Integrity is everything to us at Alliance. We're committed to doing the right thing, even when it's not the easiest path. By being transparent and honest in all our actions, we build trust and respect within our team and with our clients."
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8babfb46392752863731740cf9b9fec9393a4b25bfd58bf56d31120f71382b95?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f",
      title: "1% Better Everyday",
      description: "Each day, we strive to be just a little bit better than the day before. This mindset of continuous growth and improvement helps us innovate and deliver better service to our clients, while also fostering personal and professional growth within our team."
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e6a141bfdc730d3b21436b933cd3a0d86616dd93313892c64261ae8eca6f0fe6?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f",
      title: "Keep It Simple",
      description: "We aim to cut through the noise and focus on clear, straightforward solutions. By keeping things simple, we reduce stress for our team and make it easier to deliver top-notch service to our clients."
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d889c3a268e0acb0cdeb7cd334c3b76b2525f14a4edb50139a91ad6a3b7a2790?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f",
      title: "Determined to Provide Value",
      description: "We're on a mission to provide real value to every one of our clients. We're always looking for ways to improve and add value, ensuring that everyone benefits from our team's hard work and dedication."
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/fd22661bc4cfcc16358ac1a22ff6ece17b1e66d3d9882ac57c6a26eb8d1471d6?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f",
      title: "Be Proud of Who You Are, Be Proud of What You Do",
      description: "At Alliance, we celebrate our people. We want our team to take pride in who they are and the amazing work they do. When our employees feel proud of their contributions, it shines through in the superior service they provide to our clients."
    }
  ];

  return (
    <section className={styles.ourValues}>
      <h2 className={styles.title}>Our Values</h2>
      <div className={styles.valuesContainer}>
        {values.map((value, index) => (
          <div key={index} className={styles.valueCard}>
            <img src={value.icon} alt="" className={styles.valueIcon} />
            <h3 className={styles.valueTitle}>{value.title}</h3>
            <p className={styles.valueDescription}>{value.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default OurValues;