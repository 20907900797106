import React from 'react';
import styles from '../../Components/ServiceHero/HeroSection.module.css';

function HeroSection({ onRequestQuoteClick }) {
  return (
    <section className={`${styles.heroSection} conciergePage`}>
      <div className={styles.imageContainer}>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/717187176c07d78cdadae6c8005ffd872f59505c2ef56c4a01fded9ac4302aac?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f"
          alt="Concierge service representative"
          className={styles.heroImage}
        />
      </div>
      <div className={styles.contentContainer}>
        <h1 className={styles.title}>
          Providing Personalized Support and Security With Our Professional
          Concierge Services
        </h1>
        <p className={styles.description}>
          <strong>
            <em>
              With Alliance's concierge team, you can relax and leave the
              details to us. Our dedicated concierge staff will greet guests,
              manage deliveries, and handle special requests. We work hard to
              ensure every aspect of your strata living is comfortable and
              stress-free.
            </em>
          </strong>
          <br />
          <br />
          We're dedicated to creating a warm, welcoming community that feels
          like home, offering security and convenience for residents and
          visitors alike.
        </p>
        <button
          className={styles.ctaButton}
          onClick={onRequestQuoteClick}
        >
          Learn More
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f"
            alt=""
            className={styles.buttonIcon}
          />
        </button>
      </div>
    </section>
  );
}

export default HeroSection;
