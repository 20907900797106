import React from "react";
import styles from "./ServicesOffered.module.css";

const ServicesOffered = () => {
  const services = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1d15e500d1bb93603ab6c1a603c989e08a0be165229e34b7c12830df7f7b0fd9?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f",
      text: "Security and administrative duties"
    },
   
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3ddef3744ad0c320202d94b2eda91de545fb91b6b70360faebb3f9c040fca566?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f",
      text: "Move management"
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/056c68937efe0b3d76fedb62a8af65eb17b709edd5fe9d8649f6cc435264d221?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f",
      text: "Cleaning of common areas including exterior grounds, lobby/foyer, elevators, hallways, amenity room, parkade, garbage room, swimming pool, guest suite and more"
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c7510d75a036cc5a174412f6ea81d8d760d01fe2311fcdaa4b972e18b867e849?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f",
      text: "Booking management"
    },
  ];

  return (
    <section className={styles.servicesSection}>
      <h2 className={styles.sectionTitle}>Caretaking Services We Offer</h2>
      <div class={styles.servicesWrapper}>
      <div className={styles.servicesContainer}>
        <p className={styles.servicesIntro}>Your strata building will have a dedicated caretaker experienced in handling the following:</p>
        <ul className={styles.servicesList}>
          {services.map((service, index) => (
            <li key={index} className={styles.serviceItem}>
              <img src={service.icon} alt="" className={styles.serviceIcon} />
              <p>{service.text}</p>
            </li>
          ))}
        </ul>
        </div>
      </div>
    </section>
  );
};

export default ServicesOffered;