import React from "react";
import styles from './HiringProcess.module.css';

function HiringProcess() {
  return (
    <section className={styles.hiringProcessContainer}>
      {/* Image Container on the Left */}
      <div className={styles.imageContainer}>
        <img 
          loading="lazy" 
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/0a18d46acf823b140013b6f101d07d2092952238b71cd101ba8e79dd9d7ce6e8?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
          className={styles.processImage} 
          alt="Illustration of hiring process" 
        />
      </div>

      {/* Text Container on the Right */}
      <div className={styles.infoContent}>
        <h2 className={styles.title}>How does our hiring process work?</h2>
        <p className={styles.description}>
          If your resume fits the position, you can expect:
          <ul>
            <li>A phone-screening call from our HR department.</li>
            <li>The selected candidates will be sent to a video interview.</li>
            <li>There might be a third phase where you'll meet our operations manager.</li>
            <li>If everything is okay, you'll be invited to complete your hiring process!</li>
          </ul>
          If you have any questions, don't hesitate to reach out.<br />
          <strong>Thank you for applying with us!</strong>
        </p>
        <a href="https://podio.com/webforms/23094720/1644867" className={styles.applyButton}>
          <span className={styles.buttonText}>Apply Now</span>
          <img 
            loading="lazy" 
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
            className={styles.buttonIcon} 
            alt="Arrow icon"
          />
        </a>
      </div>
    </section>
  );
}

export default HiringProcess;
