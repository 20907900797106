import React from 'react';
import styles from './JobPosting.module.css';

const Section = ({ title, content }) => {
  return (
    <section>
      {title && <h2 className={styles.sectionTitle}>{title}</h2>}
      <div className={styles.sectionContent}>{content}</div>
    </section>
  );
};

export default Section;