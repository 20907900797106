import axios from 'axios';

const FormDataHandler = async ({ formType, formData, onStatusUpdate }) => {
    const API_URL = process.env.REACT_APP_API_URL || 'https://alliance-backend-delta.vercel.app/api/submitForm';

    try {
        onStatusUpdate && onStatusUpdate('Sending...');
        const response = await axios.post(API_URL, { formType, formData });
        const resultStatus = response.data.status === 'success' ? 'Form submitted successfully!' : 'Failed to submit the form.';
        onStatusUpdate && onStatusUpdate(resultStatus);
    } catch (error) {
        const errorMessage = 'Failed to submit the form. Please try again.';
        console.error('Error sending data:', error);
        onStatusUpdate && onStatusUpdate(errorMessage);
    }
};

export default FormDataHandler;
