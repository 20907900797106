import React from "react";
import styles from "./HeroSection.module.css";
import s5 from "../../Assets/teamslider/LuisHR.png"
import s6 from "../../Assets/teamslider/team.png"
import s7 from "../../Assets/teamslider/image51.png"
import s8 from "../../Assets/teamslider/image71.JPG"
import s9 from "../../Assets/teamslider/image8.JPG"
import s10 from "../../Assets/teamslider/selfatia.png"



function HeroSection() {
  return (
    <section className={styles.heroSection}>
      <h1 className={styles.heroTitle}>
        Experience a Career at Alliance Maintenance: Your Efforts Are Supported, Valued, and Rewarded
      </h1>
      <button className={styles.exploreButton}>
      <a href="https://ca.indeed.com/cmp/Alliance-Maintenance-Ltd.-1/jobs" 
      target="_blank"
      rel="noopener noreferrer"
      
      >
        Explore Open Roles
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/40c23c79c92a38cb05675433f812c3d7a47e97d5dafd98185686ecf104354086?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="" className={styles.buttonIcon} />
       </a>
      </button>
<div className={styles.imageGallery}>
  <img src={s5} alt="Team member" className={styles.galleryImage} loading="lazy" />
  <img src={s6} alt="Team member" className={styles.galleryImage} loading="lazy" />
  <img src={s7} alt="Team member" className={styles.galleryImage} loading="lazy" />
  <img src={s10} alt="Team member" className={styles.galleryImage} loading="lazy" />
  <img src={s8} alt="Team member" className={styles.galleryImage} loading="lazy" />
  <img src={s9} alt="Team member" className={styles.galleryImage} loading="lazy" />
</div>
    </section>
  );
}

export default HeroSection;