import React from 'react';
import styles from './JobPosting.module.css';
import Section from './Section';


const JobPosting = () => {
  const jobDetails = {
    title: "Building Manager",
    sections: [
      {
        content: (
          <>
            Are you looking for a great teamwork environment and people to support you in your career? Then welcome to Alliance Maintenance! We believe that taking good care of our staff is the secret to our success.
            <br /><br />
            <span className={styles.boldText}>Alliance Maintenance</span> is an expanding building maintenance company servicing residential strata facilities throughout the Metro Vancouver area.
            <ul>
              <li><span className={styles.boldText}>Steady employment.</span> You will work at the same place, at the same time.</li>
              <li><span className={styles.boldText}>On-site training.</span> No direct experience? We can show you how to do it!</li>
              <li><span className={styles.boldText}>Schedule your bills</span> with the peace of mind that you'll be paid every 15th and 30th (or last day) of the month through a direct deposit. Plus 4% Vacation Pay is added to each paycheque.</li>
              <li><span className={styles.boldText}>Health benefits for full-time employees.</span> After your probation, you'll be eligible for medical, vision, and dental care.</li>
            </ul>
          </>
        )
      },
      {
        title: "Job Details",
        content: "We are seeking to meet experienced, energetic, and quality candidates for Building Manager. The Building Manager is the first contact for building issues and emergencies. As a Building Manager, you'll serve the Strata Council and the Property Manager to help maintain the organization and security of the building."
      },
      {
        title: "Available Shifts",
        content: "Monday to Friday from 8:00 AM to 4:00 PM"
      },
      {
        title: "Responsibilities and Duties",
        content: (
          <ul>
            <li>Perform daily maintenance and repair duties including but not limited to changing light bulbs, painting, minor drywall repair, power washing, salting, and shoveling sidewalks.</li>
            <li>Assist the property manager to post notices, issue letters and enforce strata rules.</li>
            <li>Assist tenants with their issues, in a professional and respectful manner.</li>
            <li>Meet contractors on site to coordinate/manage renovation projects in the building or who are working on common property.</li>
            <li>Conduct administrative and clerical responsibilities like responding to e-mails, booking amenities, maintaining supply of supplies and materials, and logging incidents.</li>
            <li>Check building premises for deficiencies and cleanliness and report building issues in writing.</li>
            <li>Program enter-phone codes and fobs and review and save camera footage as needed.</li>
            <li>Assist First Responders and execute emergency protocols if needed.</li>
            <li>Perform weekly and monthly security inspections (Generators, Fire Alarm, and others) as required.</li>
            <li>Daily inspection of the parkade and monitoring of illegal parking.</li>
            <li>Facilitate move-ins/move-outs.</li>
            <li>Fire panel monitoring.</li>
            <li>Other duties as required.</li>
          </ul>
        )
      },
      {
        title: "Knowledge and Skills",
        content: (
          <ul>
            <li>Great customer service skills.</li>
            <li>Knowledge of the Residential Tenancy Act is an asset.</li>
            <li>Strong communication, organizing, planning & decision-making skills.</li>
            <li>Demonstrate good judgment in problem-solving and conflict resolution.</li>
            <li>Computer skills (Microsoft Office, Internet, Email, and Messaging proficiency).</li>
            <li>Able to respond to emergencies on weekends and outside standard work hours.</li>
            <li>Ability to multi-task, prioritize workload to finish daily/monthly activities & meet deadlines.</li>
          </ul>
        )
      }
    ]
  };

  return (
    <main className={styles.container}>
      <article className={styles.contentWrapper}>
        <header className={styles.headerContainer}>
          <h1 className={styles.jobTitle}>{jobDetails.title}</h1>
          <a
            href="https://ca.indeed.com/cmp/Alliance-Maintenance-Ltd.-1/jobs"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.applyButton}
          >
            <span className={styles.buttonText}>Apply Now</span>
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f"
              alt=""
              className={styles.buttonIcon}
              loading="lazy"
            />
          </a>
        </header>
        <div className={styles.mainContent}>
          {jobDetails.sections.map((section, index) => (
            <Section
              key={index}
              title={section.title}
              content={section.content}
            />
          ))}
        </div>
      </article>
    </main>
  );
};

export default JobPosting;
