import React from 'react';
import { Helmet } from 'react-helmet';
import metadata from '../../Config/MetaData.json';

const Meta = ({ page }) => {
  // Get page-specific metadata or fall back to default
  const meta = metadata[page] || metadata.default;

  return (
    <Helmet>
      {/* Basic SEO Tags */}
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords} />
      <link rel="canonical" href={meta.url} />

      {/* Open Graph / Facebook */}
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:type" content={meta.type || 'website'} />
      <meta property="og:url" content={meta.url} />
      <meta property="og:image" content={meta.image} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.image} />
    </Helmet>
  );
};

export default Meta;
