import React from 'react';
import styles from './TeamMembers.module.css';
import HiringBox from './HiringBox';

const teamMembers = [
  { 
    name: 'Jatin', 
    position: 'Building Manager', 
    imageSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/f57dac67ad50f7a3fa94f63aeecb60d56e7469b729a0137c3d11b5597510818c?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f', 
    serviceIcon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/056c68937efe0b3d76fedb62a8af65eb17b709edd5fe9d8649f6cc435264d221?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f'
  },
  { 
    name: 'Bhuwan', 
    position: 'Operations Supervisor', 
    imageSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/3f8d28cb226cc5e123d7569419962ab465c58ee696e3f3d606f4f9a9a0f45dd0?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f',
    serviceIcon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/056c68937efe0b3d76fedb62a8af65eb17b709edd5fe9d8649f6cc435264d221?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f'
  },
  { 
    name: 'Sukhpreet', 
    position: 'HR Specialist', 
    imageSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/5df1636cee41ba77661377156fdeaafeaabd60149005fee3b99bada00ee5e2ee?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f', 
    serviceIcon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/056c68937efe0b3d76fedb62a8af65eb17b709edd5fe9d8649f6cc435264d221?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f'
  },
  { 
    name: 'Brenda', 
    position: 'Operation Supervisor', 
    imageSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/65d705b5d221becd47c7f197cb1c2649733dc1acf5d39b7f6fdd88bd4115294c?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f',
    serviceIcon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/056c68937efe0b3d76fedb62a8af65eb17b709edd5fe9d8649f6cc435264d221?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f' 
  },
  { 
    name: 'Nester', 
    position: 'Project Technician', 
    imageSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/c2b50adfcaa9d3b649c476800b86ca0c6c4ef4578270c68740b1168fa031bfb1?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f',
    serviceIcon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/056c68937efe0b3d76fedb62a8af65eb17b709edd5fe9d8649f6cc435264d221?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f' 
  },
  { 
    name: 'Roxy', 
    position: 'Operation Manager', 
    imageSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/cd29c35a12cca4d43987286bfa671ae895816ded44b564b87113800f66c847a4?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f',
    serviceIcon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/056c68937efe0b3d76fedb62a8af65eb17b709edd5fe9d8649f6cc435264d221?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f' 
  },
  { 
    name: 'Luis', 
    position: 'HR Manager', 
    imageSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/cd29c35a12cca4d43987286bfa671ae895816ded44b564b87113800f66c847a4?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f',
    serviceIcon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/056c68937efe0b3d76fedb62a8af65eb17b709edd5fe9d8649f6cc435264d221?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f' 
  }
];

const TeamMembers = () => {
  return (
    <section className={styles.teamSection}>
      <h2 className={styles.sectionTitle}>Meet Our Dedicated Team Members</h2>
      <div className={styles.teamGrid}>
        {/* Column 1 */}
        <div className={styles.column1}>
          <div className={`${styles.teamMember} ${styles.teamMember1}`}>
            <div className={styles.overlay}>
              <p className={styles.name}>{teamMembers[0].name}</p>
              <p className={styles.position}>{teamMembers[0].position}</p>
            </div>
          </div>
          <div className={`${styles.teamMember} ${styles.teamMember2}`}>
            <div className={styles.overlay}>
             <p className={styles.name}>{teamMembers[1].name}</p>
              <p className={styles.position}>{teamMembers[1].position}</p>
            </div>
          </div>
        </div>

        {/* Column 2 */}
        <div className={styles.column2}>
        <div className={`${styles.teamMember} ${styles.teamMember7}`}>
            <div className={styles.overlay}>
              <p className={styles.name}>{teamMembers[6].name}</p>
              <p className={styles.position}>{teamMembers[6].position}</p>
            </div>
          </div>
          <div className={`${styles.teamMember} ${styles.teamMember3}`}>
            <div className={styles.overlay}>
              <p className={styles.name}>{teamMembers[2].name}</p>
              <p className={styles.position}>{teamMembers[2].position}</p>
            </div>
          </div>
        </div>

        {/* Column 3 */}
        <div className={styles.column3}>
          <div className={`${styles.teamMember} ${styles.teamMember4}`}>
            <div className={styles.overlay}>
              <p className={styles.name}>{teamMembers[3].name}</p>
              <p className={styles.position}>{teamMembers[3].position}</p>
            </div>
          </div>
          <div className={`${styles.teamMember} ${styles.teamMember5}`}>
            <div className={styles.overlay}>
              <p className={styles.name}>{teamMembers[4].name}</p>
              <p className={styles.position}>{teamMembers[4].position}</p>
            </div>
          </div>
        </div>

        {/* Column 4 */}
        <div className={styles.column4}>
          <div className={`${styles.teamMember} ${styles.teamMember6}`}>
            <div className={styles.overlay}>
              <p className={styles.name}>{teamMembers[5].name}</p>
              <p className={styles.position}>{teamMembers[5].position}</p>
            </div>
          </div>
          <div className={styles.hiringBox}>
            <HiringBox />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamMembers;
