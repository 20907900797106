import React from 'react';
import styles from './ContactInfo.module.css';

const ContactInfo = () => {
  return (
    <div className={styles.contactInfoContainer}>
      <div className={styles.infoGrid}>
        <div className={styles.infoLabels}>
          <p>Toll-free</p>
          <p>Fax</p>
          <p>Email</p>
        </div>
        <div className={styles.infoValues}>
          <p>1.888.351.5283</p>
          <p>604.398.8824</p>
          <p className={styles.email}>info@alliancecleans.com</p>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;