import React, { useState } from 'react';
import Swal from 'sweetalert2';
import styles from './LeadMagnetLandingPage.module.css';

const LeadMagnetLandingPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    consent: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.firstName || !formData.lastName || !formData.email || !formData.consent) {
      alert('Please fill in all fields and agree to the consent checkbox.');
      return;
    }

    setIsLoading(true);

    // Format date as YYYY/MM/DD
    const today = new Date();
    const formattedDate = `${today.getFullYear()}/${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(2, '0')}`;

    // Prepare data for submission
    const formattedData = [
      formattedDate,
      formData.firstName,
      formData.lastName,
      formData.email,
    ];

    // Submit data directly to the specified API
    try {
      const response = await fetch('https://alliance-backend-delta.vercel.app/api/submitForm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          formType: 'leadMagnet',
          formData: formattedData,
        }),
      });

      const result = await response.json();
      setIsLoading(false);

      if (response.ok && result.status === 'success') {
        Swal.fire({
          title: 'Success!',
          text: 'Your form has been submitted. Download will begin shortly.',
          icon: 'success',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          width: '300px',
        });

        // Redirect to the download link after form submission
        setTimeout(() => {
          window.location.href = '/resources/Strata-Budgeting-Guide-by-Alliance-Maintenance-Final.pdf';
        }, 2000);
      } else {
        throw new Error('Form submission failed');
      }
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        title: 'Error!',
        text: 'There was an issue submitting the form. Please try again.',
        icon: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        width: '300px',
      });
      console.error('Form submission error:', error);
    }
  };

  return (
    <main className={styles.landingPage}>
      <section className={styles.contentWrapper}>
        <div className={styles.innerContent}>
          <img 
            loading="lazy" 
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/dcd4cf9131b7cbbe5f1dccfd9d96b5248decf67d9ef7c835eadfd8f712725e4e?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
            alt="Alliance Maintenance Logo" 
            className={styles.logo} 
          />
          <div className={styles.mainContent}>
            <div className={styles.contentColumns}>
              <div className={styles.leftColumn}>
                <div className={styles.textContent}>
                  <h1 className={styles.mainHeading}>
                    Essential Budgeting Guide for Council Members: Avoid Strata Budgeting Pitfalls.
                  </h1>
                  <div className={styles.descriptionWrapper}>
                    <p className={styles.description}>
                      Don't let financial mismanagement put your strata at risk. Get our free Strata Budgeting Survival Guide and learn how to streamline your budget and prepare for emergencies.
                    </p>
                    <p className={styles.disclaimer}>
                      Disclaimer: Alliance Maintenance is committed to safeguarding the personal information entrusted to us by our clients. For more information, please review our{' '}
                      <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                        <u>Privacy Policy</u>
                      </a>.
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.rightColumn}>
                <div className={styles.formWrapper}>
                  <form onSubmit={handleSubmit}>
                    <div className={styles.formGroup}>
                      <label htmlFor="firstName" className={styles.formLabel}>First Name</label>
                      <input 
                        type="text" 
                        id="firstName" 
                        name="firstName"
                        className={styles.formInput} 
                        placeholder="Your first name" 
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className={styles.formGroup}>
                      <label htmlFor="lastName" className={styles.formLabel}>Last Name</label>
                      <input 
                        type="text" 
                        id="lastName" 
                        name="lastName"
                        className={styles.formInput} 
                        placeholder="Your last name" 
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className={styles.formGroup}>
                      <label htmlFor="email" className={styles.formLabel}>Email Address</label>
                      <input 
                        type="email" 
                        id="email" 
                        name="email"
                        className={styles.formInput} 
                        placeholder="Email Address" 
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className={styles.checkboxWrapper}>
                      <input 
                        type="checkbox" 
                        id="consent" 
                        name="consent"
                        className={styles.checkbox} 
                        checked={formData.consent}
                        onChange={handleChange}
                        required
                      />
                      <label htmlFor="consent" className={styles.checkboxLabel}>
                        I have read and understand the privacy policy and agree to receive personalized communications from Alliance Maintenance Ltd. I can remove my consent at any time.
                      </label>
                    </div>
                    <button type="submit" className={styles.submitButton}>
                      {isLoading ? 'Submitting...' : 'Download'}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default LeadMagnetLandingPage;
