import React, { useRef } from "react";
import HeroSection from "./HeroSection";
import ServicesOffered from "./ServicesOffered";
import CallToAction from "./CallToAction";
import QuoteForm from "./QuoteForm";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import styles from "./StrataCaretaking.module.css";
import Meta from "../../Components/Meta/Meta";

const StrataCaretaking = () => {

    // Create a ref for the RequestQuote section
    const requestQuoteRef = useRef(null);

    // Scroll function to pass to HeroSection
    const scrollToRequestQuote = () => {
      requestQuoteRef.current.scrollIntoView({ behavior: "smooth" });
    };

  return (
    <div className={styles.servicePage}>
      <Meta page="service-caretaking" /> 
      <Header />
      <main>
        <HeroSection onRequestQuoteClick={scrollToRequestQuote} />
        <ServicesOffered />
        <CallToAction />
        <QuoteForm ref={requestQuoteRef} />
      </main>
      <img 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/6622f4d3200e435f3ba16b88296c16d7ddf993b47c81f5400ee2de2639ef0a44?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
        alt="" 
        className={styles.decorativeImage} 
      />
      <Footer />
    </div>
  );
};

export default StrataCaretaking;