import React from "react";
import styles from "./ServicesOffered.module.css";

function ServicesOffered() {
  const services = [
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/390f5559850ce18b06f6611345aedafae606936fdfdb39119b7b0ca6cd034300?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", text: "Access and security" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a0f3b29f9516e863a77f89bbf8c66b4ec8472669adff325aabd2f9b21b95cfd6?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", text: "Trades coordination" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/9c733e28c9df5cf2214f942b31c11f186fd9f97ac7f32bbd7198033802483b75?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", text: "Move management" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/252f4fe2d4c846bea53a68d6b90569955058f5ff26b4b30f0c7b59650e8dc531?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", text: "Booking management" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f32085637e0d8becdd5b5b821ed68436c67cfd586e698388dfba65b91af8901f?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", text: "Parking management" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/997f9d6f4156a70f92b9ba3eb5b55ccd598793cbf4bf86b802a943e90d56eb19?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", text: "Property manager relations" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/db54f756043fa97d63bf0feed56c20c185890e3ed5db22a0c1e0901c7155127e?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", text: "Tenant relations and management" },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8872ecf4366a7d224ded65ef220976a16fcdb3c0bd91987ab4278149d2088409?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", text: "Package management" }
  ];

  return (
    <section className={styles.servicesSection}>
      <h2 className={styles.sectionTitle}>
        Concierge <br /> Services We Offer
      </h2>
      <div className={styles.contentWrapper}>
      <div className={styles.servicesContent}>
        <p className={styles.servicesDescription}>
          Our experienced and professional concierge team is happy to offer the following services to strata buildings across Metro Vancouver:
        </p>
        <ul className={styles.servicesList}>
          {services.map((service, index) => (
            <li key={index} className={styles.serviceItem}>
              <img src={service.icon} alt="" className={styles.serviceIcon} />
              <span>{service.text}</span>
            </li>
          ))}
        </ul>
        </div>
      </div>
    </section>
  );
}

export default ServicesOffered;