import React from 'react';
import styles from './HiringBox.module.css';

const HiringBox = () => {
  return (
    <div className={styles.hiringBox}>
      <h3 className={styles.hiringTitle}>We are hiring!</h3>
      <hr className={styles.divider} />
      <p className={styles.hiringText}>
        We're all about creating a supportive and positive environment where you feel valued and empowered. As children of immigrants, our founders have instilled a deep commitment to diversity and inclusion.
        <br /><br />
        Our team comes from all over the world, speaking many languages, making Alliance a vibrant and inclusive place to work. Come be part of our inclusive community and let your strengths make an impact.
      </p>
      <a href="https://ca.indeed.com/cmp/Alliance-Maintenance-Ltd.-1/jobs" className={styles.hiringButton}>
        See Open Positions
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/b25c9f636d7f0e4429bbb35d604904131845f60c0eb51c7d40539f59a685ffd0?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="" className={styles.buttonIcon} />
      </a>
    </div>
  );
};

export default HiringBox;