import React, { useRef } from "react";
import Header from "../../Components/Header/Header";
import HeroSection from "./HeroSection";
import ServicesOffered from "./ServicesOffered";
import RequestQuote from "./RequestQuote";
import Footer from "../../Components/Footer/Footer";
import styles from "./ConciergeService.module.css";
import Meta from "../../Components/Meta/Meta";

function ConciergeService() {
  // Create a ref for the RequestQuote section
  const requestQuoteRef = useRef(null);

  // Scroll function to pass to HeroSection
  const scrollToRequestQuote = () => {
    requestQuoteRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={styles.servicePage}>
      <Meta page="service-concierge" /> 
      <Header />
      <main>
        {/* Pass scroll function to HeroSection */}
        <HeroSection onRequestQuoteClick={scrollToRequestQuote} />
        <ServicesOffered />
        {/* Attach ref to RequestQuote component */}
        <RequestQuote ref={requestQuoteRef} />
      </main>
      <img 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/6622f4d3200e435f3ba16b88296c16d7ddf993b47c81f5400ee2de2639ef0a44?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
        alt="" 
      />
      <Footer />
    </div>
  );
}

export default ConciergeService;