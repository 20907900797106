import React from "react";
import styles from "./CallToAction.module.css";

const CallToAction = () => {
  return (
    <section className={styles.ctaSection}>
      <h2 className={styles.ctaTitle}>Let Alliance handle the hassle—have a dedicated team member as your strata building's go-to person!</h2>
    </section>
  );
};

export default CallToAction;