import React, { useState } from "react";
import { useEffect } from "react";
import styles from "./JobListings.module.css";
import image1 from '../../Assets/careers/Erick222.png'

// Updated jobPositions array with individual links
const jobPositions = [
  { 
    title: "Building Manager", 
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/771f7402af915a811ffae50e1bc32b233c02a15e1d6ec2e84f64ae69d2ec6fa6?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", 
    description: "We are seeking a proactive, organized individual to oversee building operations, maintenance, and tenant relations. Join us to make a positive impact in property management.", 
    link: "/job-posting/building-manager" 
  },
  { 
    title: "Cleaner", 
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/10b1a381b8ad9b34369b6461cece9ff8fd19eb0584a2cbc733c478fcbc8e3e96?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", 
    description: "We would love to meet energetic and willing-to-learn candidates for a building cleaner position. Here, you will be responsible for cleaning and maintaining the common areas of a residential building.", 
    link: "/job-posting/cleaner" 
  },
  { 
    title: "Caretaker", 
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/168c037ef9c425c3b63df710c768dcfef7a964587748dc8167be3fb1e6259736?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", 
    description:"This position is for individual who can oversee maintenance, cleanliness, and daily operations of a strata property. Ideal for someone detail-oriented with a commitment to creating a safe and pleasant environment for residents",
    link: "/job-posting/caretaker" 
  },
  { 
    title: "Concierge", 
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/26912446e3fcf793653dbee68fbdba217b3632e8320a7c6b20f1e9f3d5eb8695?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f", 
    description:"Join our team to provide exceptional service in a premium strata building. Responsible for assisting residents, coordinating services, and ensuring a welcoming, well-maintained environment.",
    link: "/job-posting/concierge" 
  }
];

function JobListings() {
  const [expanded, setExpanded] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleDescription = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <section className={styles.jobListings}>
      <div className={styles.jobListingsContent}>
        <div className={styles.jobListingsHeader}>
          <h2 className={styles.jobListingsTitle}>We are hiring!</h2>
          <p className={styles.jobListingsSubtitle}>
            Check Available Job Positions at Alliance Vancouver
          </p>
        </div>
        <p className={styles.jobListingsDescription}>
          As a growing Vancouver strata maintenance company, we're always looking for talented, dedicated individuals who are passionate about their jobs. With Alliance, you can use your unique skills to help provide exceptional service to high-end residential buildings across Vancouver (most are transit accessible). If you're looking for a friendly and supportive work environment where you can continue to grow your career, we want to hear from you!
        </p>
      </div>

      {isMobile ? (
        // Mobile layout
        <div className={styles.mobileLayout}>
          <div className={styles.teamMemberContainer}>
            <img 
              src={image1} 
              alt="Team member" 
              className={styles.teamMemberImage} 
            />
          </div>
          <ul className={styles.positionsList}>
            {jobPositions.map((position, index) => (
              <li
                key={index}
                className={`${styles.positionItem} ${expanded === index ? styles.expanded : ''}`}
                onClick={() => toggleDescription(index)}
              >
                <div className={styles.positionTitle}>
                  {position.title}
                  <img src={position.icon} alt="" className={styles.positionIcon} />
                </div>
                <p className={styles.positionDescription}>{position.description}</p>
                <button className={styles.readDetailsButton}>
                  <a href={position.link}>
                    Read Details
                    <img 
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd" 
                      alt="arrow icon" 
                      className={styles.buttonIcon} 
                    />
                  </a>
                </button>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        // Desktop layout (unchanged)
        <div className={styles.jobPositions}>
          <div className={styles.teamMemberContainer}>
            <div className={styles.gradientBackground}></div>
            <img 
              src={image1} 
              alt="Team member" 
              className={styles.teamMemberImage} 
            />
          </div>
          <ul className={styles.positionsList}>
            {jobPositions.map((position, index) => (
              <li
                key={index}
                className={`${styles.positionItem} ${expanded === index ? styles.expanded : ''}`}
              >
                <div
                  className={styles.positionTitle}
                  onClick={() => toggleDescription(index)}
                >
                  {position.title}
                  <img src={position.icon} alt="" className={styles.positionIcon} />
                </div>
                <p className={styles.positionDescription}>{position.description}</p>
                <button className={styles.readDetailsButton}>
                  <a href={position.link}>
                    Read Details
                    <img 
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd" 
                      alt="arrow icon" 
                      className={styles.buttonIcon} 
                    />
                  </a>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </section>
  );
}
export default JobListings;
