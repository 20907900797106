import React from 'react';
import styles from '../../Components/ServiceHero/HeroSection.module.css';
import heroImage from '../../Assets/servicePage/cleaning/cleaning_hero.png'; // Update the path to the correct image

function HeroSection({ onRequestQuoteClick }) {
  return (
    <section className={styles.heroSection}>
      <div className={styles.imageContainer}>
        <img
          src={heroImage}
          alt="Strata Cleaning Service"
          className={styles.heroImage}
        />
      </div>
      <div className={styles.contentContainer}>
        <h1 className={styles.title}>Strata Cleaning Services You Can Trust</h1>
        <p className={styles.description}>
          <strong>
            <em>
              As a dedicated strata cleaning company, we recognize that each
              residential strata building has its own unique characteristics.
              That's why we commit to providing a tailor-made cleaning program
              designed specifically for your property.
            </em>
          </strong>
          <br />
          <br />
          Far from the industry's 'general cleaning' standards, our services are
          customized to meet your exact needs. Our team includes dedicated account
          supervisors, mobile supply delivery, flexible staff schedules, and certified
          carpet and floor professionals, guaranteeing your strata property is
          consistently well-cared for.
        </p>
        <button
          className={styles.ctaButton}
          onClick={onRequestQuoteClick}
        >
          Request Now
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f"
            alt=""
            className={styles.buttonIcon}
          />
        </button>
      </div>
    </section>
  );
}

export default HeroSection;
