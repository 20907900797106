import React from 'react';
import styles from '../../Components/ServiceHero/HeroSection.module.css';

function HeroSection({ onRequestQuoteClick }) {
  return (
    <section className={styles.heroSection}>
      <div className={styles.imageContainer}>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/3960966ee02ca7af50482547662b9e80d88d6622017ab09a607c1fee98ec2460?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f"
          alt="Strata building maintenance"
          className={styles.heroImage}
        />
      </div>
      <div className={styles.contentContainer}>
        <h1 className={styles.title}>
          Caretaking Services for Strata Buildings Across Vancouver
        </h1>
        <p className={styles.description}>
          <strong>
            <em>
              Is your strata building in need of a caretaker to assist with
              daily administration? Alliance Maintenance offers a team of
              dedicated professionals who handle both cleaning and caretaking,
              saving your strata council's valuable time.
            </em>
          </strong>
          <br />
          <br />
          With our Strata Caretaking services, you benefit from our general
          strata cleaning plus an onsite caretaker for everyday administration.
        </p>
        <button
          className={styles.ctaButton}
          onClick={onRequestQuoteClick}
        >
          Request Now
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f"
            alt=""
            className={styles.buttonIcon}
          />
        </button>
      </div>
    </section>
  );
}

export default HeroSection;
