import React from 'react';
import styles from './AboutSection.module.css';

function AboutSection() {
  return (
    <section className={styles.aboutSection}>
      <div className={styles.imageContainer}>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/59f901e81abb8839155b0dbb69f41688f0cfc52ab370f0dfd44ba9d6f202b98a?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="Operations supervisor" className={styles.supervisorImage} />
        <div className={styles.supervisorInfo}>
          <div className={styles.supervisorName}>
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/2c240a3111b9566f1263398c5c5a13d50eed5b887d444bca03422b1066f724b2?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="" className={styles.nameIcon} />
            <span>Aster</span>
          </div>
          <div className={styles.supervisorRole}>Operations supervisor</div>
        </div>
      </div>
      <div className={styles.aboutContent}>
      <div   className={styles.gradientBackground}  style={{ backgroundImage: "url('/gradient-image.png')" }}
/>
        <h2 className={styles.aboutTitle}>About Alliance </h2>
        <p className={styles.aboutDescription}>
          As one of the leading strata maintenance companies in Vancouver, B.C., we pride ourselves in being different. Our entire company is built around taking good care of our people so that they can take good care of our clients.
          <br /><br />
          At Alliance, our top priority is supporting our team of professionals so that they can focus on what they're passionate about: helping others through high quality customer service. By providing comprehensive cleaning, management, and maintenance, our team works hard to preserve the integrity and value of your strata building.
          <br /><br />
          Let us show you the Alliance difference. Our team of highly skilled, friendly, and dedicated professionals are genuinely passionate about providing exceptional service and getting the job done right.
        </p>
        <button className={styles.exploreButton}>
          <a href="/about-us">
          Explore More About Alliance </a>
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="" className={styles.buttonIcon} />
        </button>
      </div>
    </section>
  );
}

export default AboutSection;