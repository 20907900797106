import React, { useState, useEffect } from "react";
import { HashLink } from 'react-router-hash-link';
import styles from "./Header.module.css"; // Desktop styles
import mobileStyles from "./Headermobile.module.css"; // Mobile styles
import { useMediaQuery } from "react-responsive";

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 991 });

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Custom scroll function with offset
  const scrollWithOffset = (el) => {
  const yOffset = -80; // Adjust this value based on your navbar height
  const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: 'smooth' });
};

  return (
    <header className={`${isMobile ? mobileStyles.header : styles.header} ${isScrolled ? (isMobile ? mobileStyles.scrolled : styles.scrolled) : ""}`}>
  <div className={`${isMobile ? mobileStyles.logoAndHamburger : styles.logoAndHamburger}`}>
  {isMobile && (
    <div className={mobileStyles.hamburgerIcon} onClick={toggleMenu}>
      ☰
    </div>
  )}
  <img 
    src="https://cdn.builder.io/api/v1/image/assets/TEMP/9bd6f358abc35b190837cf0b9e93e3dcc47264d9653ee96ff8638022af9ebf83?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
    alt="Alliance Maintenance Logo" 
    onClick={() => (window.location.href = "/")}
    style={{ cursor: "pointer" }}
    className={isMobile ? mobileStyles.logo : styles.logo} 
  />
</div>



      {/* Desktop Navigation */}
      {!isMobile && (
        <nav className={styles.navigation}>
          <ul>
            <li> <HashLink smooth to="/#service-cards" scroll={scrollWithOffset}>
            Services
          </HashLink></li>
            <li><a href="/about-us">About Us</a></li>
            <li><a href="/blog">Blog</a></li>
            <li><a href="/careers">Careers</a></li>
          </ul>
        </nav>
      )}

      {/* Desktop Contact Information */}
      {!isMobile && (
        <div className={styles.contactInfo}>
          <div className={styles.phoneNumber}>
            <img 
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/a9e872071122445d5b78c84c00f0d2f96705953a6e8d51c28a5542eedbff43e7?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
              alt="Phone Icon" 
              className={styles.phoneIcon} 
            />
            <span>604-398-8824</span>
          </div>
          <a href="/contact">
            <button className={styles.contactButton}>
              Contact Us
              <img 
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
                alt="" 
                className={styles.buttonIcon} 
              />
            </button>
          </a>
        </div>
      )}

      {/* Mobile Full-Screen Menu */}
      {isMobile && isMenuOpen && (
        <div className={`${mobileStyles.mobileMenu} ${isMenuOpen ? "active" : ""}`}>
          <img 
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/9bd6f358abc35b190837cf0b9e93e3dcc47264d9653ee96ff8638022af9ebf83?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
      alt="Alliance Maintenance Logo" 
      className={mobileStyles.expandedMenuLogo} onClick={() => (window.location.href = "/")}// New class to style the logo in expanded menu
    />
          <div className={mobileStyles.closeIcon} onClick={toggleMenu}>✕</div>
          <ul className={mobileStyles.mobileNav}>
            <li> <HashLink smooth to="/#service-cards">
            Services
          </HashLink></li>
            <li><a href="/about-us">About Us</a></li>
            <li><a href="/blog">Blog</a></li>
            <li><a href="/careers">Careers</a></li>
          </ul>
          {/* Mobile Contact Information */}
          <div className={mobileStyles.mobileContactInfo}>
            
            <a href="/contact">
              <button className={mobileStyles.contactButton}>
                Contact Us
                <img 
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
                  alt="" 
                  className={mobileStyles.buttonIcon} 
                />
              </button>
            </a>

            <div className={mobileStyles.phoneNumber}>
              <img 
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/a9e872071122445d5b78c84c00f0d2f96705953a6e8d51c28a5542eedbff43e7?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
                alt="Phone Icon" 
                className={mobileStyles.phoneIcon} 
              />
              <span> <a href="tel:+16043968824" className="phoneLink">(604) 398-8824</a></span>
            </div>
          </div>
          {/* Horizontal line */}
<hr className={mobileStyles.horizontalLine} />

{/* Social Icons Section */}
  <div className={mobileStyles.socialIcons}>

              <a href="https://www.facebook.com/alliancecleans/" target="blank" aria-label="Facebook">
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/0f6b125562c01a3385583b91ea21760b4583afdead402c52592208e0051ad145?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="" className={styles.socialIcon} />
              </a>
              <a href="https://www.instagram.com/alliancecleans/" target="blank" aria-label="Instagram">
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/a656cf57ef3f1e10c9e71af019f51187e7f5576eb84050730fb399e74652fda1?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="" className={styles.socialIcon} />
              </a>
              <a href="https://www.linkedin.com/company/alliance-maintenance-ltd-/?originalSubdomain=ca" target="blank" aria-label="LinkedIn">
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/8acdcf35d82fa1df8548e45881036adaae5cb7d47e842abda6f978d633ef0f85?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="" className={styles.socialIcon} />
              </a>
      
  </div>
        </div>
      )}
    </header>
  );
}

export default Header;
