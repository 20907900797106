import React from "react";
import styles from "./ServiceCard.module.css";
import { Link } from "react-router-dom";

function ServiceCard({ title, description, imageUrl, link }) {
  return (
    <article className={styles.cardWrapper}>
      <img src={imageUrl} alt={title} className={styles.cardImage} />
      <div className={styles.cardContent}>
        <h2 className={styles.cardTitle}>{title}</h2>
        <p className={styles.cardDescription}>{description}</p>
      </div>
      <button className={styles.learnMoreButton}>
        <Link to={link}>Learn more</Link>
      </button>
    </article>
  );
}

export default ServiceCard;
