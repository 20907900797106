import React from 'react';
import Slider from 'react-slick';
import styles from './TrustedBySection.module.css';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

function TrustedBySection() {
  const trustedLogos = [
    require("../../Assets/clientlogosaml/Client10Remi.png"),
    require("../../Assets/clientlogosaml/Client11Wesgroup.png"),
    require("../../Assets/clientlogosaml/Client12Anthem.png"),
    require("../../Assets/clientlogosaml/Client13Solterra.png"),
    require("../../Assets/clientlogosaml/Client14Millenium.png"),
    require("../../Assets/clientlogosaml/Client15ConcordPacific.png"),
    require("../../Assets/clientlogosaml/Client16Bosa.png"),
    require("../../Assets/clientlogosaml/Client17Boffo.png"),
    require("../../Assets/clientlogosaml/Client2RanchoLogo.png"),
    require("../../Assets/clientlogosaml/Client3AssociaLogo.png"),
    require("../../Assets/clientlogosaml/Client4FSRLogo.png"),
    require("../../Assets/clientlogosaml/Client7Stratawest.png"),
    require("../../Assets/clientlogosaml/Client8ProfileProperties.png"),
    require("../../Assets/clientlogosaml/Client9QuayPacific.png"),
    require("../../Assets/clientlogosaml/Client_1_ AWM_logo.png"),  
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className={styles.trustedBySection}>
      <h2>Trusted By</h2>
      <Slider {...settings}>
        {trustedLogos.map((logo, index) => (
          <div key={index} className={styles.logoContainer}>
          <img src={logo} alt={`Trusted Logo ${index + 1}`} className={styles.trustedLogo} />
        </div>        
        ))}
      </Slider>
    </section>
  );
}

export default TrustedBySection;
