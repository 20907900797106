import React from 'react';
import styles from './BlogComingSoon.module.css';

function BlogComingSoon() {
  return (
    <div className={styles.container}>
       
      <h1 className={styles.title}>Our Blog is Coming Soon!</h1>
      <p className={styles.description}>
        Exciting articles, expert tips, and insights are on the way. Stay tuned for our official launch where we’ll dive into everything from cleaning best practices to building management advice and more!
      </p>
      
      <div className={styles.categoryPreview}>
        <h2 className={styles.subtitle}>Upcoming Blog Topics:</h2>
        <ul className={styles.categories}>
          <li className={styles.categoryItem}>Cleaning Tips</li>
          <li className={styles.categoryItem}>Building Management</li>
          <li className={styles.categoryItem}>Sustainability</li>
          <li className={styles.categoryItem}>Resident Safety</li>
          <li className={styles.categoryItem}>Maintenance Insights</li>
        </ul>
      </div>

    
    </div>
  );
}

export default BlogComingSoon;
