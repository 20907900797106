import React from 'react';
import Header from '../../Components/Header/Header';
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import TrustedBySection from './TrustedBySection';
import TestimonialSection from './TestimonialSection';
import ContactForm from './ContactForm';
import Footer from '../../Components/Footer/Footer';
import styles from './HomePage.module.css';
import StrataCareComponent from './StrataCareComponent';
import Meta from '../../Components/Meta/Meta';

function HomePage() {
  return (
    <div className={styles.homepage}>
      <Meta page="home" /> 
      <Header />
      <main>
        <HeroSection />        
        <StrataCareComponent/>
        <AboutSection />
        <TrustedBySection />
        <TestimonialSection />
        <ContactForm />
      </main>
      <img 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/6622f4d3200e435f3ba16b88296c16d7ddf993b47c81f5400ee2de2639ef0a44?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
        alt="" 
        className={styles.decorativeImage} 
      />
      <Footer />
    </div>
  );
}

export default HomePage;