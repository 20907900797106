import React from 'react';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';
import SocialLinks from './SocialLinks';
import styles from './ContactUs.module.css';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import HiringProcess from './HiringProcess';
import s1 from '../../Assets/separators/separator1.png'

const ContactUs = () => {
  return (
  <div className={styles.ContactUsContainer}>
    <Header/>
    <main>
    <section className={styles.contactSection}>
      <div className={styles.contentWrapper}>
        <div className={styles.infoColumn}>
          <h1 className={styles.title}>Contact us</h1>
          <h2 className={styles.subtitle}>Thank You for Reaching Out</h2>
          <p className={styles.description}>
            We recognize you have a choice between many different strata maintenance companies in Vancouver and thank you for your continued loyalty and business. Whether you're a new customer or have been working with us for years, we greatly value your business and are here to support you.
          </p>
          <ContactInfo />
          <SocialLinks />
        </div>
        <div className={styles.formColumn}>
          <ContactForm />
        </div>
      </div>
     

    </section>
    <img src = {s1} className={styles.separator}/>
    {HiringProcess && <HiringProcess />}
    </main>
    <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/6622f4d3200e435f3ba16b88296c16d7ddf993b47c81f5400ee2de2639ef0a44?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="" className={styles.footerDecoration} />
    <Footer/>
  </div>
  );
};

export default ContactUs;